import React from "react";

const config = require("../../../config.json")
const compagnyColorPrimary = config.APP_COMPAGNYCOLORPRIMARY;

const iconLogo = {
  marginRight: "5px",
  objectFit: "contain",
};
const Mail = {
  display: "flex",
  alignItems:"center",
  margin: "0px 0px 15px 0px",
  fontSize: "12px",
  lineHeight: "1.38",
  textDecoration: "none",
};
const MailLink = {
  color: compagnyColorPrimary,
  fontSize: "12px",
  fontWeight: "bold",
  textDecoration: "underline",
  margin: "0px 0px 0px 0px",
};

export default function howCome() {
  return (
    <a style={Mail} href="https://parking.myclientisrich.com" target="_blank" rel="noreferrer">
      <img src={`https://files.myclientisrich.com/signatures_mcir/v2/icons/hand.png`} style={iconLogo} alt="logo" width="25" height="16"></img>
      <span style={MailLink}>
        Comment venir à l'agence
      </span>
    </a>
  );
}

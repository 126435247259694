import React from "react";

const config = require("../../../config.json")
const client = config.APP_CLIENT;
const compagnyColorPrimary = config.APP_COMPAGNYCOLORPRIMARY;

const iconLogo = {
  marginRight: "10px",
  transform: "translateY(5px)",
};
const PhoneStyle = {
  fontSize: "12px",
  color: compagnyColorPrimary,
  margin: "0px 0px 3px",
};
const PhoneLink = {
  textDecoration: "none",
  color: compagnyColorPrimary,
  paddingRigth: "5px",
  marginRigth: "5px",
  lineHeight: "0",
};

function telWithoutSpaces(number) {
  return number.replace(/\s/g, "").replace('(', '').replace(')', '');
}

export default function Phone(phone) {
  return (
    <p style={PhoneStyle}>
      <a href={`tel:${telWithoutSpaces(phone.phone)}`}
        style={PhoneLink}
      >
        {phone.phone}
      </a>
    </p>
  );
}

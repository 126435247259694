import React, { useState } from "react";
import SignatureFormatter from "./components/SignatureFormatter";
import styled from "@emotion/styled";
import "./style.css";
import Select from 'react-select'


const options = [
  { value : "my-client-is-rich", label: "Par défaut"},
  { value : "gauthier", label: "Gauthier"},
  { value : "mickael", label: "MickMick"},
  { value : "fx", label: "FX"},
  { value : "benjamin", label: "Ben"},
  { value : "claire", label: "Claire"},
  { value : "camille", label: "Camille"},
  { value : "charlotte", label: "Charlotte"},
  { value : "nico", label: "Nicolas"},
  { value : "tom", label: "Tom"},
  { value : "kevin", label: "Kévin"},
  { value : "eva", label: "Eva"},
  { value : "helene", label: "Hélène"},
  { value : "lucie", label: "Lucie"},
  { value : "marine", label: "Marine"},
  { value : "ines", label: "Inès"},
]

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  width: 100%;
  display: block;
`;
const InputWrapper = styled.div`
  margin: 0 0;
  padding-top: 20px;
`;
const leftPart = {
  width: "100%",
  minHeight: "100vh"
}
const inputWrapper = {
  display: "flex",
  flexDirection: "row",
  gap: "15px"
}

const userData = {
  firstname: "Prénom",
  lastname: "Nom",
  urlImage: "",
  job: "Titre du poste",
  phone: "+33 (0)9 72 37 60 65",
  mobile: "",
  postalAdress: "Strasbourg, 2a rue Moll",
  email: "Adresse email",
  website: "myclientisrich.com",
  linkedin: "https://www.linkedin.com/company/agence-my-client-is-rich",
  facebook: "https://www.facebook.com/myclientisrich.lapage/",
  instagram: "https://www.instagram.com/myclientisrich/",
  imgName: 'my-client-is-rich',
  slogan: "slogan"
};

export default function App() {
  const [user, setUser] = useState(userData);
  const [isChecked, setIsChecked] = useState(false);
  const [typeImage, setTypeImage] = useState('gif');
  const [gifChecked, setGifChecked] = useState(true);
  const [staticChecked, setStaticChecked] = useState(false);


  const handleGifChange = (event) => {
    setGifChecked(event.target.checked);
    setStaticChecked(false);
    setTypeImage(event.target.value);
  };

  const handleImageChange = (e) => {
    setUser({ ...user, "imgName": e.value });
  };
  const handleStaticChange = (event) => {
    setGifChecked(false);
    setStaticChecked(event.target.checked);
    setTypeImage(event.target.value);
  };


  const userInfoChange = e => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  function copyToClipboard() {
    let tableToCopy = document.querySelector('.wrapper-table')
    let range = document.createRange();
    range.selectNode(tableToCopy);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
  }

  function responseCopy() {
    let button = document.querySelector('button');
      button.classList.add('copied');
    setTimeout(() => {
      button.classList.remove('copied');
    }, 2000);
  }

  return (
    <Wrapper>
      <Container>
        <section className="formulaire">
          <div style={leftPart}>
            <SignatureFormatter user={user} message={isChecked} typeImage={typeImage} />
          </div>
          <div className="rightPart">
            <h1 className="title">Signature Generator</h1>
            <h2 className="title">By my client is rich</h2>
            <p className="inputLabel">Noms</p>
            {/* ***************          NOM ET PRENOM        *************** */}
            <InputWrapper style={inputWrapper}>
              <input
                name="firstname"
                onChange={userInfoChange}
                value={user.firstname}
                placeholder="Prénom"
                onBlur={() => {
                  user.firstname === "" ? setUser({ ...user, firstname: userData.firstname }) : setUser({ ...user, firstname: user.firstname });
                }}
                onFocus={() => {
                  user.firstname === userData.firstname ? setUser({ ...user, firstname: "" }) : setUser({ ...user, firstname: user.firstname });
                }}
              />
              <input
                name="lastname"
                onChange={userInfoChange}
                value={user.lastname}
                placeholder="Nom"
                onBlur={() => {
                  user.lastname === "" ? setUser({ ...user, lastname: userData.lastname }) : setUser({ ...user, lastname: user.lastname });
                }}
                onFocus={() => {
                  user.lastname === userData.lastname ? setUser({ ...user, lastname: "" }) : setUser({ ...user, lastname: user.lastname });
                }}
              />
            </InputWrapper>
            {/* ***************          TITRE DU POSTE        *************** */}
            <InputWrapper>
              <input
                name="job"
                onChange={userInfoChange}
                value={user.job}
                placeholder="Titre du poste"
                onBlur={() => {
                  user.job === "" ? setUser({ ...user, job: userData.job }) : setUser({ ...user, job: user.job });
                }}
                onFocus={() => {
                  user.job === userData.job ? setUser({ ...user, job: "" }) : setUser({ ...user, job: user.job });
                }}
              />
            </InputWrapper>
            <InputWrapper>
              <input
                name="mobile"
                onChange={userInfoChange}
                value={user.mobile}
                placeholder="Téléphone mobile (optionnel)"
                onBlur={() => {
                  user.mobile === "" ? setUser({ ...user, mobile: userData.mobile }) : setUser({ ...user, mobile: user.mobile });
                }}
                onFocus={() => {
                  user.mobile === userData.mobile ? setUser({ ...user, mobile: "" }) : setUser({ ...user, mobile: user.mobile });
                }}
              />
            </InputWrapper>


            {/* ***************          IMAGE        *************** */}
            <p className="inputLabel">Image</p>
            {/* ***************          CENTRER IMAGE        *************** */}
            <div className="selectImage">
              <Select
              options={options}
              onChange={handleImageChange}
              placeholder="Qui suis-je ? "
              />
            </div>
            {/* ***************          IMAGE STATIQUE OU GIF        *************** */}
            <div className="wrapperTypeImage">
              <div className="checkItem gif">
                <input
                  type="checkbox"
                  checked={gifChecked}
                  onChange={handleGifChange}
                  value="gif"
                />
              </div>
              <div className="checkItem static">
                <input
                  type="checkbox"
                  checked={staticChecked}
                  onChange={handleStaticChange}
                  value="static"
                />
              </div>
            </div>
            <button
              className="copyButton"
              onClick={() => {
                copyToClipboard();
                responseCopy();
              }}
            ><p className="btnCopy"></p></button>
          </div>
        </section>
      </Container>
    </Wrapper>
  );
}

import React from "react";

const config = require("../../../config.json")
const client = config.APP_CLIENT;
const compagnyColorPrimary = config.APP_COMPAGNYCOLORPRIMARY;

const iconLogo = {
  marginRight: "5px",
  objectFit: "contain",
};
const Mail = {
  display: "flex",
  alignItems:"center",
  margin: "0px 0px 0px",
  fontSize: "12px",
  lineHeight: "1.38",
  textDecoration: "none",
};
const MailLink = {
  textDecoration: "none",
  color: compagnyColorPrimary,
  fontSize: "12px",
  margin: "0px 0px 0px",
};

export default function PostalAdress(adress) {
  return (
    <p style={Mail}>
      <img src={`https://files.myclientisrich.com/signatures_mcir/v2/icons/marker.png`} style={iconLogo} alt="logo" width="15" height="20"></img>
      <span style={MailLink}>
        {adress.adress}
      </span>
    </p>
  );
}
